/* ===================================
   Sweet Alert Z-Index Override
   Ensures Sweet Alert modals appear 
   above all other elements including
   PrimeNG dialogs
=================================== */
.swal-on-top {
  z-index: 10000 !important;
}

/* Existing styles below */
.meeting-iframe-wrapper{display: block;}
.meeting-iframe{ height: 100%; width: 100%; min-height: 60rem;}

/* MAIN LEGISLATION COMPONENT */
.legislation-tabview .p-tabview-nav {border-bottom: none;}
.legislation-tabview .p-tabview-nav li {border: 1px solid #ccc;border-radius: 5px 5px 0px 0px;margin: 0 .125em 1px 0;}
.legislation-tabview .p-tabview-nav li.p-highlight {border: 1px solid #3c82f6;background: #3c82f6;}
.legislation-tabview .p-tabview-nav li:not(.p-highlight) a.p-tabview-nav-link{background: #6d6d6d;color: white;}
.legislation-tabview .p-tabview-nav li a.p-tabview-nav-link{background: #3c82f6;color: white;}
.legislation-tabview .p-tabview-panels{ border: 1px solid #ccc;padding: 1rem;}
.legislation-table .p-datatable-thead tr th,
.legislation-table .p-datatable-tbody td.table-name{background: #4E71BD;color: #ffffff;}
@keyframes shake { 0% { transform: translateX(0); } 25% { transform: translateX(-5px); } 50% { transform: translateX(5px); } 75% { transform: translateX(-5px); } 100% { transform: translateX(0); } }
.shake { animation: shake 0.5s ease-in-out; }
@media (max-width: 768px) { ::ng-deep .legislation-tabview .p-tabview-nav { overflow-x: auto; scroll-behavior: smooth; } }
/* Styles for mobile view */
@media only screen and (max-width: 960px) {.legislation-list-wrapper {display: block;}.legislation-table {display: none;}}
/* Styles for larger screens */
@media only screen and (min-width: 961px) {.legislation-list-wrapper {display: none;}.legislation-table {display: block;}}


/* LEGISLATION EXAMPLE TABLE */
.legisexample-table .p-datatable-header{background-color: #3c82f6;color: white;}
.legisexample-table .overlay-container {position: relative;}
.legisexample-table .overlay {position: absolute; z-index: 1; top: 0; left: 0; width: 100%; height: 100%; background-color: rgba(0, 0, 0, 0.4); display: flex; flex-direction: column; justify-content: center; align-items: center; border-radius: inherit;}
.legisexample-table .overlay-icon {font-size: 3rem; color: #fff;}
.legisexample-table .overlay-button {margin-top: 1rem; opacity: 0; transition: opacity 0.3s ease;}
.legisexample-table .overlay-container:hover .overlay-button {opacity: 1;}
.legisexample-table .p-datatable-tbody .article-wrapper{min-height: 56px;}
.legisexample-table .p-datatable-tbody .article-wrapper .news-index{display: inline-block;width: 5%;vertical-align: top;}
.legisexample-table .p-datatable-tbody .article-wrapper .news-info{display: inline-block;width: 95%;vertical-align: top;}
.legisexample-table .p-datatable-tbody .article-wrapper .news-title .redacted-text { filter: blur(2px); -webkit-filter: blur(2px); color: transparent; text-shadow: 0 0 2px rgba(0, 0, 0, 0.5); }
.legisexample-table .p-datatable-tbody .article-wrapper .news-title .redacted-text:before { content: attr(data-text); position: absolute; filter: blur(2px); -webkit-filter: blur(2px); }


/* LEGISLATION PREVIEW TABLE - SEARCHCONFIG */
.legispreview-table .p-datatable-header{background: linear-gradient(to bottom, #f6f7f9 0, #ebedf0 100%);}
.legispreview-table .p-datatable-tbody .article-wrapper{min-height: 56px;}
.legispreview-table .p-datatable-tbody .article-wrapper .news-index{display: inline-block;width: 5%;vertical-align: top;}
.legispreview-table .p-datatable-tbody .article-wrapper .news-info{display: inline-block;width: 95%;vertical-align: top;}
.legispreview-table .p-datatable-tbody .article-wrapper .news-title .redacted-text { filter: blur(2px); -webkit-filter: blur(2px); color: transparent; text-shadow: 0 0 2px rgba(0, 0, 0, 0.5); }
.legispreview-table .p-datatable-tbody .article-wrapper .news-title .redacted-text:before { content: attr(data-text); position: absolute; filter: blur(2px); -webkit-filter: blur(2px); }


/* LEGISLATION IFRAME */
.legis-iframe-wrapper .legis-iframe { height: 100%; width: 100%; min-height: 720px; border: 1px solid #000; }
/* Styles for tablets and smaller devices */
@media (max-width: 768px) { .pdf-button-wrapper, .pdf-button { display: block; } .legis-iframe-wrapper { display: none; } }
/* Styles for devices larger than tablets */
@media (min-width: 769px) { .pdf-button-wrapper, .pdf-button { display: none; } .legis-iframe-wrapper { display: block; } }

/* LEGISLATION NO OBJECT */
.preview-article-dialog .p-dialog-content .legislation-content{font-family: "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;font-size: 13px; color: black;}
.preview-article-dialog .p-dialog-content .legislation-content p{font-family: "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;font-size: 13px;margin: 0 0 10px;line-height: 1.4em;}
.preview-article-dialog .p-dialog-content .noai-legis-wrapper { position: relative; width: 100%; height: 100%; padding: 10px 5px; margin-bottom: 20px;min-height: 450px;}
.preview-article-dialog .p-dialog-content .button-overlay { position: absolute; top: 0; left: 0; right: 0; bottom: 0; background-color: #c1c1c1; display: flex; flex-direction: column; justify-content: center; align-items: center; z-index: 2; border-radius: 5px;}
.preview-article-dialog .p-dialog-content .button-message { text-align: center; color: white; padding: 20px; border: 1px solid white; border-radius: 10px; background-color: rgba(255, 255, 255, 0.1); max-width: 80%; }
.preview-article-dialog .p-dialog-content .button-message-title { font-size: 1.5em; font-weight: bold; }
.preview-article-dialog .p-dialog-content .noai-button { font-size: 2em; margin-top: 20px; cursor: pointer; padding: 10px 20px; border: none; background-color: #007BFF; color: white; border-radius: 5px; box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); }
.preview-article-dialog .p-dialog-content .noactive-overlay{background-color: #b1b6bc6e;color: black;}
.preview-article-dialog .p-dialog-content .legis-disclaimer-label{color: #31708f;font-size: 12px;}
.preview-article-dialog .p-dialog-content .legis-disclaimer-text{font-style: italic;font-size: 11px;}@media (max-width: 768px) { .pdf-button-wrapper, .pdf-button { display: block; } .legis-iframe-wrapper { display: none; } }
@media (min-width: 769px) { .pdf-button-wrapper, .pdf-button { display: none; } .legis-iframe-wrapper { display: block; } }

/* Demo Signup Dialog Styles */
.demo-signup-dialog .p-dialog-header {
  padding: 1.5rem;
  border-bottom: 1px solid var(--surface-border);
}

.demo-signup-dialog .p-dialog-content {
  padding: 0;
}

.demo-signup-dialog .demo-form-content {
  padding: 1.5rem;
}

.demo-signup-dialog .demo-info-banner {
  background: var(--primary-50);
  border: 1px solid var(--primary-100);
  border-radius: var(--border-radius);
  padding: 1rem;
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.demo-signup-dialog .demo-info-banner i {
  color: var(--primary-color);
  font-size: 1.5rem;
}

.demo-signup-dialog .demo-info-banner p {
  margin: 0;
  color: var(--primary-700);
  font-size: 0.875rem;
  line-height: 1.5;
}

.demo-signup-dialog .form-fields {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}

.demo-signup-dialog .field-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.demo-signup-dialog .field-group label {
  font-weight: 500;
  color: var(--text-color);
}

.demo-signup-dialog .p-input-icon-left {
  width: 100%;
}

.demo-signup-dialog .p-input-icon-left i {
  color: var(--text-color-secondary);
}

.demo-signup-dialog .p-input-icon-left input {
  width: 100%;
  padding-left: 2.5rem;
}

.demo-signup-dialog .demo-tips {
  margin-top: 1.5rem;
  padding-top: 1.5rem;
  border-top: 1px solid var(--surface-border);
}

.demo-signup-dialog .demo-tips h4 {
  color: var(--text-color);
  font-size: 0.875rem;
  margin: 0 0 0.75rem 0;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.demo-signup-dialog .demo-tips h4 i {
  color: var(--primary-color);
}

.demo-signup-dialog .demo-tips ul {
  margin: 0;
  padding-left: 1.5rem;
  color: var(--text-color-secondary);
  font-size: 0.875rem;
}

.demo-signup-dialog .demo-tips li {
  margin-bottom: 0.5rem;
}

.demo-signup-dialog .dialog-footer {
  padding: 1.5rem;
  border-top: 1px solid var(--surface-border);
  display: flex;
  justify-content: flex-end;
}

/* Responsive adjustments */
@media screen and (max-width: 576px) {
  .demo-signup-dialog {
    width: 90vw !important;
  }
  
  .demo-signup-dialog .demo-form-content {
    padding: 1rem;
  }
  
  .demo-signup-dialog .dialog-footer {
    padding: 1rem;
  }
}

/* Verification Dialog Styles */
.verification-dialog .p-dialog-header {
  padding: 1.5rem;
  border-bottom: 1px solid var(--surface-border);
}

.verification-dialog .p-dialog-header h2 {
  margin: 0;
  font-size: 1.5rem;
  color: var(--text-color);
}

.verification-dialog .p-dialog-content {
  padding: 0;
}

.verification-content {
  padding: 1.5rem;
}

.verification-info {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  margin-bottom: 2rem;
  padding: 1rem;
  background: var(--surface-ground);
  border-radius: var(--border-radius);
}

.verification-info i {
  font-size: 1.5rem;
  color: var(--primary-color);
}

.verification-info .info-text {
  flex: 1;
}

.verification-info .email-sent {
  margin: 0 0 0.5rem 0;
  color: var(--text-color);
}

.verification-info .hint {
  margin: 0;
  font-size: 0.875rem;
  color: var(--text-color-secondary);
}

.code-input-section {
  margin-bottom: 2rem;
}

.code-input-section label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
  color: var(--text-color);
}

.code-input-section .p-inputnumber {
  width: 100%;
}

.verification-footer {
  padding-top: 1.5rem;
  border-top: 1px solid var(--surface-border);
  display: flex;
  justify-content: flex-end;
}

/* Update Demo Signup Dialog Styles */
.demo-signup-dialog .p-dialog-header h2 {
  margin: 0;
  font-size: 1.5rem;
  color: var(--text-color);
}

.demo-signup-dialog .demo-info-banner {
  background: var(--surface-ground);
  border: none;
  border-radius: var(--border-radius);
  padding: 1.25rem;
  margin-bottom: 2rem;
}

.demo-signup-dialog .demo-info-banner .banner-content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.demo-signup-dialog .banner-header {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.demo-signup-dialog .banner-header i {
  font-size: 1.25rem;
  color: var(--primary-color);
}

.demo-signup-dialog .banner-header h3 {
  margin: 0;
  font-size: 1rem;
  color: var(--text-color);
}

.demo-signup-dialog .feature-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
  margin: 0;
  padding: 0;
  list-style: none;
}

.demo-signup-dialog .feature-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.875rem;
  color: var(--text-color-secondary);
}

.demo-signup-dialog .feature-item i {
  color: var(--primary-color);
  font-size: 1rem;
}

/* Responsive adjustments */
@media screen and (max-width: 576px) {
  .verification-dialog,
  .demo-signup-dialog {
    width: 90vw !important;
  }

  .verification-content,
  .demo-form-content {
    padding: 1rem;
  }

  .verification-footer,
  .dialog-footer {
    padding: 1rem;
  }
}

/* Demo Report Dialog Styles */
.demo-report-dialog .p-dialog-header {
  padding: 1.5rem 2rem;
  background: var(--surface-ground);
  border-bottom: 1px solid var(--surface-border);
}

.demo-report-dialog .dialog-header-content {
  text-align: center;
}

.demo-report-dialog .dialog-header-content h2 {
  margin: 0 0 0.5rem 0;
  font-size: 1.75rem;
  color: var(--text-color);
}

.demo-report-dialog .dialog-header-content p {
  margin: 0;
  color: var(--text-color-secondary);
}

.demo-report-dialog .p-dialog-content {
  padding: 0;
}

.demo-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  min-height: 600px;
}

/* Form Column Styles */
.form-column {
  padding: 2rem;
  border-right: 1px solid var(--surface-border);
}

.form-header {
  margin-bottom: 2rem;
}

.form-header .step-indicator {
  display: inline-block;
  padding: 0.25rem 0.75rem;
  background: var(--primary-50);
  color: var(--primary-color);
  border-radius: var(--border-radius);
  font-size: 0.875rem;
  margin-bottom: 0.75rem;
}

.form-header h3 {
  margin: 0;
  font-size: 1.25rem;
  color: var(--text-color);
}

.form-fields {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.field-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.field-group label {
  font-weight: 500;
  color: var(--text-color);
}

.form-footer {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: flex-start;
}

.demo-note {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--text-color-secondary);
  font-size: 0.875rem;
  margin: 0;
}

/* Features Column Styles */
.features-column {
  background: var(--surface-ground);
  padding: 2rem;
}

.features-content h3 {
  margin: 0 0 1.5rem 0;
  font-size: 1.25rem;
  color: var(--text-color);
}

.feature-cards {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  margin-bottom: 2rem;
}

.feature-card {
  background: var(--surface-card);
  padding: 1.5rem;
  border-radius: var(--border-radius);
  text-align: center;
  transition: transform 0.2s;
}

.feature-card:hover {
  transform: scale(1.05);
}

/* Demo Report Dialog Styles */
.demo-report-dialog .p-dialog-header {
  padding: 1.5rem 2rem;
  background: var(--surface-ground);
  border-bottom: 1px solid var(--surface-border);
}

.demo-report-dialog .field-group {
  margin-bottom: 1.5rem;
}

.demo-report-dialog .field-group label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
  color: var(--text-color);
}

.demo-report-dialog .p-input-icon-left {
  width: 100%;
}

.demo-report-dialog .p-input-icon-left i {
  left: 0.75rem;
  color: var(--text-color-secondary);
}

.demo-report-dialog .p-input-icon-left input {
  width: 100%;
  padding-left: 2.5rem;
}

.demo-report-dialog .p-inputtext {
  width: 100%;
  padding: 0.75rem 0.75rem 0.75rem 2.5rem;
}

.demo-report-dialog .p-inputtext:enabled:focus {
  box-shadow: 0 0 0 2px var(--surface-ground), 0 0 0 4px var(--primary-color);
}

.demo-report-dialog .p-error {
  display: block;
  margin-top: 0.25rem;
  font-size: 0.875rem;
}